/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Deepu",
  title: "Hi all, I'm Deepak!",
  subTitle: emoji(
    "A fervent problem solver with a Master's in Computer Science from the University of Florida. My zeal transcends tech stacks and frameworks; for me, it's all about the heart of the problem. With an insatiable curiosity and a versatile skill set in machine learning, full-stack development, and cloud computing, I thrive on learning and adapting to whatever tools necessary to engineer impactful solutions. I'm not just a technologist; I'm a relentless innovator, driven to decipher complexities and transform challenges into opportunities for growth and improvement."
    ),
  resumeLink:
    "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/ddhacks007",
  linkedin: "https://www.linkedin.com/in/deepak-raju-882728152/",
  gmail: "csdeepakraju@gmail.com",
  facebook: "https://www.facebook.com/deepak.abraham.56/",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "Dynamic developer adept at crafting complex machine learning models, engineering sophisticated full-stack web applications, and seamlessly deploying scalable solutions to the cloud.  ",
  skills: [
   
    emoji("⚡ Expert in designing intuitive user interfaces with ReactJS, blending visual design with seamless functionality for an enhanced user experience."),
emoji("⚡ Skilled in building robust, scalable back-end systems with Spring Boot, FastAPI, ExpressJS, Django and Flask, ensuring efficient data management and service integration."),
emoji("⚡ Proficient in architecting and deploying advanced machine learning models using TensorFlow and PyTorch, turning complex datasets into actionable insights."),
emoji("⚡ Adept in cloud infrastructure orchestration with AWS, Kubernetes and Docker, focusing on optimized deployment, scalability, and management of complex web applications.")
 
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "TensorFlow",
      fontAwesomeClassname: "tensorflow.jpeg"
    },
    {
      skillName: "ReactJS",
      fontAwesomeClassname: "reactjs.png"
    },
    {
      skillName: "Flask",
      fontAwesomeClassname: "flask.png"
    },
    {
      skillName: "Django",
      fontAwesomeClassname: "django.png"
    },
    {
      skillName: "Fastapi",
      fontAwesomeClassname: "fastapi.jpeg"
    },
    {
      skillName: "PyTorch",
      fontAwesomeClassname: "pytorch.png"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "docker.png"
    },
    {
      skillName: "Kubernetes",
      fontAwesomeClassname: "kubernetes.png"
    },
    {
      skillName: "Spring",
      fontAwesomeClassname: "spring.png"
    },
    
    {
      skillName: "Keras",
      fontAwesomeClassname: "keras.png"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "aws.jpeg"
    },
    {
      skillName: "Quarkus",
      fontAwesomeClassname: "quarkus.jpeg"
    },
    {
      skillName: "MySQL",
      fontAwesomeClassname: "mysql.png"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "mongo.png"
    },
    {
      skillName: "Dynamodb",
      fontAwesomeClassname: "dynamodb.png"
    },
  
    
   
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Florida",
      logo: require("./assets/images/uf.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "August 2022 - April 2024",
      desc: "3.74/4.0"
      
    },
    {
      schoolName: "Anna University",
      logo: require("./assets/images/anna.png"),
      subHeader: "Bachelor of Engineering in Computer Science and Engineering",
      duration: "April 2015 - April 2019",
      desc: "7.79/10.0"

    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Machine Learning", //Insert stack or technology you have experience in
      progressPercentage: "85%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "70%" //Insert relative proficiency in percentage
    },
  
    {
      Stack: "Backend",
      progressPercentage: "95%"
    },
    {
      Stack: "Cloud",
      progressPercentage: "90%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Graduate Research Assistant ML Ops",
      company: "University of Florida",
      companylogo: require("./assets/images/uf.png"),
      date: "January 2023 – Present",
      descBullets: [
        "Orchestrated the development and deployment of cutting-edge Computer Vision and Large Language Models, leveraging cloud infrastructure for optimal performance.",
        "Developed a user-centric Image Annotation platform, substantially enhancing operational efficiency and engaging users more effectively.",
        "Designed and implemented a high-performance permit management system, streamlining processes and significantly improving system capabilities."
   ]},
    {
      role: "Software Engineer Intern",
      company: "Visteon Corporation",
      companylogo: require("./assets/images/visteon.png"),
      date: "May 2023 – Aug 2023",
      descBullets: [
        "Spearheaded a strategic migration of databases to AWS RDS, achieving enhanced scalability and significant cost reduction.",
"Pioneered automation of disaster recovery processes for core systems, substantially improving system resilience and reducing manual intervention.",
"Developed and implemented sophisticated database health monitoring scripts, significantly decreasing enterprise downtime and ensuring system reliability."
      ]
    },
    {
      role: "Software Engineer AI/Machine Learning Engineer",
      company: "Alpha Cloud Labs",
      companylogo: require("./assets/images/acl.png"),
      date: "Aug 2019 – Jul 2022",
      descBullets: ["Masterminded the implementation and deployment of advanced machine learning models and techniques, significantly optimizing data processing and analysis, while ensuring cost-effective and efficient automation of complex operational tasks.",
        "Led comprehensive software development initiatives, from enhancing RESTful APIs and front-end performance to creating robust web platforms and data management systems, ensuring streamlined workflow and improved system functionalities.",
        "Expertly managed cloud infrastructure, employing auto-scaling and deployment strategies to enhance system scalability and resilience, resulting in a significant increase in workload capacity and operational efficiency."]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  };

// Some big projects you have worked on

const bigProjects = {
 
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
};

// Blogs Section

const blogSection = {
  
};

// Talks Sections

const talkSection = {

};

// Podcast Section

const podcastSection = {
 
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  email_address: "csdeepakraju@gmail.com"
};

// Twitter Section

const twitterDetails = {

};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
